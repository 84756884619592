import TrainingRecordsTable from "./training-records-table/TrainingRecordsTable.vue";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import TrackConstants from "../../util/constants/TrackConstants";
import { getCurrentSystemType } from "../../util/user/UserManger";
import { SYSTEM_TYPE } from "../../util/constants/EnumConstants";

export default {
    name: "TrainingRecords",
    components: {
        TrainingRecordsTable,
    },
    data() {
        return {
            routerParam: {},
        };
    },
    computed: {
        /**
         * 是否是现场会议
         */
        isOfflineMeeting() {
            return this.routerParam.type == SYSTEM_TYPE.MEETING;
        },
    },
    mounted() {
        this.routerParam = this.$route.query;
    },
    methods: {
        /**
         * 点击返回
         */
        onClickPrepareEvent() {
            TibiMatomoManger.sendTrainRecordTrackEvent(TrackConstants.eventNameKeys.training_record_menu_preparation_train);
            this.$router.go(-1);
        },
    },
};
