var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "training-records",
      class: _vm.isOfflineMeeting ? "meeting-container" : "container"
    },
    [
      _c(
        "div",
        { staticClass: "top-breadcrumb-wrapper" },
        [
          _c("div", { staticClass: "breadcrumb-title" }, [_vm._v("当前位置:")]),
          _c(
            "el-breadcrumb",
            {
              staticClass: "breadcrumb-content",
              attrs: { "separator-class": "el-icon-arrow-right" }
            },
            [
              _c(
                "el-breadcrumb-item",
                {
                  staticClass: "breadcrumb-before",
                  nativeOn: {
                    click: function($event) {
                      return _vm.onClickPrepareEvent.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isOfflineMeeting ? "准备会议" : "准备培训") +
                      " "
                  )
                ]
              ),
              _c("el-breadcrumb-item", [
                _vm._v(_vm._s(_vm.isOfflineMeeting ? "会议记录" : "培训记录"))
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("TrainingRecordsTable", {
        attrs: { propsStudentListParams: _vm.routerParam, showTitleBar: false }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }